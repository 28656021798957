@import url("https://fonts.googleapis.com/css2?family=Gantari:wght@300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

.font-gantari {
  font-family: "Gantari", sans-serif;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.slick-arrow {
  position: absolute !important;
  gap: 0 !important;
 
  right: 0 !important;
  top: 110% !important;
}

@media screen and (max-width: 500px) {
  .slick-arrow {
    display: none !important;
  }
}

.slick-prev::before,
.slick-next::before {
  font-size: 30px !important; /* Set your desired font size */
  /* Add any other custom styling you want */
}
.slick-dots {
  bottom: -40px !important;
  display: block;
  width: 100%;
  list-style: none;
  text-align: center;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: white !important;
}
.slick-dots li button:before {
  font-size: 24px !important; /* Set your desired font size */
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes delay-0 {
  0% {
    animation-delay: 0s;
  }
}
@keyframes delay-1 {
  0% {
    animation-delay: 0.1s;
  }
}
/* Add more delay animations as needed */

